import BeforeAfterCapitalStack from "@/components/help-center/custom-tags/BeforeAfterCapitalStack.vue";

export const beforeAfterCapitalStack = {
  render: BeforeAfterCapitalStack,
  attributes: {
    investmentType: {
      type: String,
      required: true,
    },
  },
};
