<template>
  <div class="h-80 flex justify-center space-x-2">
    <div :class="stackWidth" class="flex flex-col">
      <h1 class="text-gray-500 font-medium text-sm mb-2 text-center">Before</h1>
      <div
        v-for="layer in beforeLayers"
        :key="layer.layerType"
        class="flex flex-grow"
      >
        <div class="w-3" :class="layer.layerColor" />
        <div
          class="w-full p-2 flex flex-col space-y-2 border-r border-t border-b border-gray-300"
        >
          <div class="text-gray-600 font-medium text-xs">
            {{ layer.layerType }}
          </div>
          <div
            v-for="companyName in layer.companies"
            :key="companyName"
            class="w-full flex-1 flex justify-center items-center border-2 border-dashed border-gray-300 text-gray-700 font-semibold"
          >
            {{ companyName }}
          </div>
        </div>
      </div>
    </div>
    <div class="self-center flex justify-center w-10">
      <ArrowRightIcon class="mt-5 w-6 text-gray-900" />
    </div>
    <div :class="stackWidth" class="flex flex-col">
      <h1 class="text-gray-500 font-medium text-sm mb-2 text-center">After</h1>
      <div
        v-for="layer in afterLayers"
        :key="layer.layerType"
        class="flex flex-grow"
      >
        <div class="w-3" :class="layer.layerColor" />
        <div
          class="w-full p-2 flex flex-col space-y-2 border-r border-t border-b border-gray-300"
        >
          <div class="text-gray-600 font-medium text-xs">
            {{ layer.layerType }}
          </div>
          <div
            v-for="companyName in layer.companies"
            :key="companyName"
            class="w-full flex-1 flex justify-center items-center border-2 border-dashed border-gray-300 text-gray-700 font-semibold"
          >
            {{ companyName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ArrowRightIcon } from "@heroicons/vue/20/solid";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useDocumentationStore } from "@/stores/documentation";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const props = defineProps(["investmentType"]);

const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const documentationStore = useDocumentationStore();
const { helpViews } = storeToRefs(documentationStore);

const stackWidth = computed(() => {
  if (isDesktop.value) {
    if (helpViews.value) {
      return "w-56";
    } else {
      return "w-40";
    }
  } else {
    return "w-32";
  }
});

const investmentTypeData = computed(
  () => investmentTypes[props.investmentType],
);
const beforeLayers = computed(() => investmentTypeData.value?.before || []);
const afterLayers = computed(() => investmentTypeData.value?.after || []);

const investmentTypes = {
  outrightSale: {
    before: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1", "Company 2"],
      },
    ],
    after: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 3"],
      },
    ],
  },
  partialInterestNewPartner: {
    before: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
    ],
    after: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1", "Company 2"],
      },
    ],
  },
  partialMultiInterestNewPartner: {
    before: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1", "Company 2"],
      },
    ],
    after: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1", "Company 2", "Company 3"],
      },
    ],
  },
  partialInterestReplacePartner: {
    before: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1", "Company 2"],
      },
    ],
    after: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1", "Company 3"],
      },
    ],
  },
  assumeExistingDebt: {
    before: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
      {
        layerType: "Senior Loan",
        layerColor: "bg-blue-600",
        companies: ["Company 3"],
      },
    ],
    after: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 2"],
      },
      {
        layerType: "Senior Loan",
        layerColor: "bg-blue-600",
        companies: ["Company 3"],
      },
    ],
  },
  loanOrigination: {
    before: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
    ],
    after: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
      {
        layerType: "Senior Loan",
        layerColor: "bg-blue-600",
        companies: ["Company 2"],
      },
    ],
  },
  juniorLoanOrigination: {
    before: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
    ],
    after: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
      {
        layerType: "Junior Loan 1",
        layerColor: "bg-teal-200",
        companies: ["Company 3"],
      },
      {
        layerType: "Senior Loan",
        layerColor: "bg-blue-600",
        companies: ["Company 2"],
      },
    ],
  },
  juniorLoanOrigination2: {
    before: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
      {
        layerType: "Senior Loan",
        layerColor: "bg-blue-600",
        companies: ["Company 2"],
      },
    ],
    after: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
      {
        layerType: "Junior Loan 1",
        layerColor: "bg-teal-200",
        companies: ["Company 3"],
      },
      {
        layerType: "Senior Loan",
        layerColor: "bg-blue-600",
        companies: ["Company 2"],
      },
    ],
  },
  juniorLoanOrigination3: {
    before: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
    ],
    after: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
      {
        layerType: "Junior Loan 2",
        layerColor: "bg-teal-300",
        companies: ["Company 4"],
      },
      {
        layerType: "Junior Loan 1",
        layerColor: "bg-teal-200",
        companies: ["Company 3"],
      },
      {
        layerType: "Senior Loan",
        layerColor: "bg-blue-600",
        companies: ["Company 2"],
      },
    ],
  },
  refinancing: {
    before: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
      {
        layerType: "Junior Loan 1",
        layerColor: "bg-teal-200",
        companies: ["Company 3"],
      },
      {
        layerType: "Senior Loan",
        layerColor: "bg-blue-600",
        companies: ["Company 2"],
      },
    ],
    after: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
      {
        layerType: "Senior Loan",
        layerColor: "bg-blue-600",
        companies: ["Company 4"],
      },
    ],
  },
  retireLoan: {
    before: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
      {
        layerType: "Senior Loan",
        layerColor: "bg-blue-600",
        companies: ["Company 2"],
      },
    ],
    after: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
    ],
  },
  loanOutrightSale: {
    before: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
      {
        layerType: "Junior Loan 1",
        layerColor: "bg-teal-200",
        companies: ["Company 3"],
      },
      {
        layerType: "Senior Loan",
        layerColor: "bg-blue-600",
        companies: ["Company 2"],
      },
    ],
    after: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
      {
        layerType: "Junior Loan 1",
        layerColor: "bg-teal-200",
        companies: ["Company 4"],
      },
      {
        layerType: "Senior Loan",
        layerColor: "bg-blue-600",
        companies: ["Company 2"],
      },
    ],
  },
  foreclosure: {
    before: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
      {
        layerType: "Senior Loan",
        layerColor: "bg-blue-600",
        companies: ["Company 2"],
      },
    ],
    after: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 2"],
      },
    ],
  },
  foreclosure2: {
    before: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 1"],
      },
      {
        layerType: "Senior Loan",
        layerColor: "bg-blue-600",
        companies: ["Company 2"],
      },
    ],
    after: [
      {
        layerType: "Equity",
        layerColor: "bg-yellow-400",
        companies: ["Company 3"],
      },
    ],
  },
};
</script>
